.input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  color: black;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.label,
section > label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: black;
  font-weight: 200;
}

.changePasswordForm, .changePasswordForm2 {
  width: 90%;
  max-width: 500px;
  text-align: center;
}

.step1 {
  text-align: center;
}

PasswordTips{
  width: 100%;
}

.changePasswordForm2 > section > button {
  margin-right: 13px;
}